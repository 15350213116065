<template>
  <div>
    <validation-observer ref="multipleForm">
      <!-- decoration image -->
      <div class="border border-info px-2">
        <b-form-group label="Decoration Image">
          <image-form
            :caption="questions.extraFile.caption"
            :show-caption="true"
            :url="questions.extraFile.imageUrl"
            @input="handleImage($event, 'extra-file', i)"
            @fileTypeInvalid="setError([`question-image-${level}`], $event)"
            @fileSizeInvalid="setError([`question-image-${level}`], $event)"
          />
          <input
            v-model="questions.extraFile.imageUrl"
            hidden
          >
        </b-form-group>
      </div>
      <!-- Question Type -->
      <b-form-group
        label="Choice Type"
        label-for="choiceType"
      >
        <validation-provider
          v-slot="{ errors }"
          name="choiceType"
          rules="required"
          vid="choiceType"
        >
          <b-form-select
            id="problemType"
            v-model="answerChoice"
            :options="choiceTypes"
            :state="errors.length > 0 ? false : null"
          />
          <small class="text-danger">{{ errors[0] }}</small>
        </validation-provider>
      </b-form-group>
      <!-- Question Type -->
      <b-form-group
        :label="$t('labels.create-problem.question-type')"
        label-for="problemType"
      >
        <validation-provider
          v-slot="{ errors }"
          name="problemType"
          rules="required"
          vid="problemType"
        >
          <b-form-select
            id="problemType"
            v-model="questionType"
            :options="typeOptions"
            :state="errors.length > 0 ? false : null"
          />
          <small class="text-danger">{{ errors[0] }}</small>
        </validation-provider>
      </b-form-group>
      <!-- / Question Type -->
      <!-- Question -->
      <!-- Text -->
      <b-form-group
        v-show="questionType === 'text'"
        :label="$t('labels.create-problem.question')"
        label-for="question"
      >
        <validation-provider
          v-slot="{ errors }"
          rules="required"
          name="question"
          :vid="`question-text-${level}`"
        >
          <b-form-input
            v-model="questions.text"
            :state="errors.length > 0 ? false : null"
            :placeholder="$t('labels.create-problem.placeholder.question')"
            name="question"
          />
          <small class="text-danger">{{ errors[0] }}</small>
        </validation-provider>
      </b-form-group>
      <!-- /Text -->
      <!-- Image -->
      <b-form-group
        v-show="questionType === 'image'"
        label-for="question"
        :label="$t('labels.create-problem.question')"
      >
        <validation-provider
          v-slot="{ errors }"
          name="question"
          rules="required"
          :vid="`question-image-${level}`"
        >
          <image-form
            :caption="questions.caption"
            :show-caption="true"
            :url="questions.imageUrl"
            @input="handleImage($event, 'questions', i)"
            @fileTypeInvalid="setError([`question-image-${level}`], $event)"
            @fileSizeInvalid="setError([`question-image-${level}`], $event)"
          />
          <input
            v-model="questions.imageUrl"
            hidden
          >
          <small class="text-danger">{{ errors[0] }}</small><br>
        </validation-provider>
      </b-form-group>
      <!-- Image -->
      <!-- Audio -->
      <b-form-group
        v-show="questionType === 'audio'"
        :label="$t('labels.create-problem.question')"
        label-for="audio"
      >
        <Recorder
          :url="questions.audioUrl"
          @input="setAudio(0, 'questions', $event)"
          @fileTypeInvalid="setError([`answer-audio-${level}`], $event)"
          @fileSizeInvalid="setError([`answer-audio-${level}`], $event)"
        />
        <validation-provider
          v-slot="{ errors }"
          name="question"
          rules="required"
          :vid="`question-audio-${level}`"
        >
          <input
            v-model="questions.audioUrl"
            hidden
          >
          <small class="text-danger">{{ errors[0] }}</small>
        </validation-provider>
      </b-form-group>
      <!-- Audio -->
      <!-- /Question -->

      <!-- Answer Type -->
      <b-form-group
        :label="$t('labels.create-problem.answer-type')"
        label-for="problemType"
      >
        <validation-provider
          v-slot="{ errors }"
          name="problemType"
          rules="required"
          vid="problemType"
        >
          <b-form-select
            id="problemType"
            v-model="answerType"
            :options="typeOptions"
            :state="errors.length > 0 ? false : null"
          />
          <small class="text-danger">{{ errors[0] }}</small>
        </validation-provider>
      </b-form-group>
      <!-- Answer Type -->

      <!-- Answers -->
      <div
        v-for="(answer, j) in answerLength"
        :key="j"
      >
        <b-row>

          <!-- Answer -->
          <b-col md="8">
            <!-- Text -->
            <b-form-group
              v-if="answerType === 'text'"
              :label="$t('labels.create-problem.answer')"
              label-for="answer"
            >
              <validation-provider
                v-slot="{ errors }"
                rules="required"
                name="answer"
                :vid="`answer-text-${level}-${j}`"
              >
                <b-form-input
                  v-model="answers.text[j]"
                  name="answer"
                  :state="errors.length > 0 ? false : null"
                  :placeholder="$t('labels.create-problem.placeholder.answer')"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
            <!-- /Text -->
            <!-- Image -->
            <b-form-group
              v-else-if="answerType === 'image'"
              :label="$t('labels.create-problem.answer')"
              label-for="article-image"
            >
              <validation-provider
                v-slot="{ errors }"
                name="name"
                rules="required"
                :vid="`answer-image-${level}-${j}`"
              >
                <image-form
                  :url="answers.imageUrl[j]"
                  :caption="answers.caption[j]"
                  :show-caption="true"
                  @input="handleImage($event, 'answers', j)"
                  @fileTypeInvalid="setError([`answer-image-${level}-${j}`], $event)"
                  @fileSizeInvalid="setError([`answer-image-${level}-${j}`], $event)"
                />
                <input
                  v-model="answers.imageUrl[j]"
                  hidden
                >
                <small class="text-danger">{{ errors[0] }}</small><br>
              </validation-provider>
            </b-form-group>
            <!-- Image -->
            <!-- Audio -->
            <b-form-group
              v-else-if="answerType === 'audio'"
              :label="$t('labels.create-problem.answer')"
              label-for="article-image"
            >
              <Recorder
                :url="answers.audioUrl[j]"
                @input="setAudio(j, 'answers', $event)"
                @fileTypeInvalid="setError([`answer-audio-${level}-${j}`], $event)"
                @fileSizeInvalid="setError([`answer-audio-${level}-${j}`], $event)"
              />
              <validation-provider
                v-slot="{ errors }"
                name="answer"
                rules="required"
                :vid="`answer-audio-${level}-${j}`"
              >
                <input
                  v-model="answers.audioUrl[j]"
                  hidden
                >
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
            <!-- Audio -->
          </b-col>
          <!-- /Answer -->

          <b-col
            class="my-auto"
            md="2"
          >
            <BFormCheckbox
              v-model="is_correct.answer[j]"
              name="checkbox-1"
              :value="true"
            >{{ $t('labels.create-problem.is-correct') }}</BFormCheckbox>
          </b-col>

          <b-col
            class="mt-2"
            md="2"
          >
            <b-button
              v-ripple.400="'rgba(113, 102, 240, 0.15)'"
              variant="outline-danger"
              class="btn-icon mr-1"
              @click="askToDelete(j)"
            >
              <feather-icon icon="TrashIcon" />
              {{ $t("Delete") }}
            </b-button>
          </b-col>
        </b-row>
        <hr>
      </div>
      <!-- /Answers -->
      <!-- Question / Answer -->

    </validation-observer>

    <!-- delete item -->
    <b-modal
      id="modal-delete"
      ref="modal-delete"
      ok-only
      ok-variant="danger"
      :ok-title="$t('confirm')"
      modal-class="modal-danger"
      centered
      :title="$t('actions.delete-answer')"
      @ok="deleteQuestion()"
    >
      <b-card-text>
        {{ $t("questions.delete-answer") }}
      </b-card-text>
      <template #modal-footer="{ ok }">
        <b-row>
          <b-col
            md="12"
            class="d-flex justify-content-end"
          >
            <b-button
              v-ripple.400="'rgba(113, 102, 240, 0.15)'"
              variant="danger"
              :disabled="isProcessing"
              @click="ok()"
            >
              <b-spinner
                v-show="isProcessing"
                small
              />
              {{ $t("confirm") }}
            </b-button>
          </b-col>
        </b-row>
      </template>
    </b-modal>
    <!-- delete item -->

    <b-button
      v-ripple.400="'rgba(113, 102, 240, 0.15)'"
      variant="outline-primary"
      class="btn-icon"
      @click="addQuestion"
    >
      <feather-icon icon="PlusIcon" />
      {{ $t("Add-New") }}
    </b-button>
  </div>
</template>

<script>
import {
  BFormGroup,
  BRow,
  BCol,
  BButton,
  BFormInput,
  BFormSelect,
  BFormCheckbox,
  BModal,
  BCardText,
} from 'bootstrap-vue'
import { ValidationProvider, ValidationObserver } from 'vee-validate/dist/vee-validate.full'
import Ripple from 'vue-ripple-directive'
import Recorder from '../recorder.vue'
import ImageForm from '../ImageForm.vue'

export default {
  components: {
    BFormGroup,
    BRow,
    BCol,
    BButton,
    BFormInput,
    BFormSelect,
    BModal,
    BCardText,
    BFormCheckbox,
    ImageForm,
    Recorder,
    ValidationProvider,
    ValidationObserver,
  },
  directives: {
    Ripple,
  },
  props: {
    level: {
      type: Number,
      default: () => 0,
    },
  },
  data: () => ({
    questions: {
      text: '',
      image: '',
      imageUrl: '',
      caption: '',
      audio: '',
      audioUrl: '',
      extraFile: {},
    },
    answers: {
      text: [''],
      image: [''],
      imageUrl: [''],
      caption: [''],
      audio: [''],
      audioUrl: [''],
    },
    is_correct: { answer: [false] },
    deletePayloadIndex: null,
    choiceTypes: ['single', 'multiple'],
  }),
  computed: {
    typeOptions() {
      return this.$store.state.problem.typeOptions
    },
    answerChoice: {
      get() {
        return this.$store.state.problem.create.multiple.answerChoice
      },
      set(value) {
        this.$store.state.problem.create.multiple.answerChoice = value
      },
    },
    questionType: {
      get() {
        return this.$store.state.problem.create.questionType
      },
      set(value) {
        this.$store.commit('problem/SET_QA_TYPE', { type: 'questionType', value })
      },
    },
    answerType: {
      get() {
        return this.$store.state.problem.create.answerType
      },
      set(value) {
        this.$store.commit('problem/SET_QA_TYPE', { type: 'answerType', value })
      },
    },
    answerLength() {
      return this.$store.getters['problem/getAnswerLength']
    },
    activeQuestions() {
      return this.$store.getters['problem/getQuestions']
    },
    activeAnswers() {
      return this.$store.getters['problem/getAnswers']
    },
    deleteIndex() {
      return this.$store.state.problem.create.matching.deleteIndex
    },
    getIsCorrect() {
      return this.$store.getters['problem/getIsCorrect']
    },
    showError: {
      get() {
        return this.$store.state.problem.showError
      },
      set() {},
    },
  },
  watch: {
    questions: {
      deep: true,
      handler() {
        let payload
        console.log({ ...this.questions })
        if (this.questionType === 'text') {
          payload = {
            type: 'multiple', level: this.level, index: 0, question: this.questions.text, extraFile: this.questions.extraFile,
          }
        } else if (this.questionType === 'image') {
          payload = {
            type: 'multiple',
            level: this.level,
            index: 0,
            question: {
              image: this.questions.image, imageUrl: this.questions.imageUrl, caption: this.questions.caption, extraFile: this.questions.extraFile,
            },
          }
        } else if (this.questionType === 'audio') {
          payload = {
            type: 'multiple', level: this.level, index: 0, question: { audio: this.questions.audio, audioUrl: this.questions.audioUrl, extraFile: this.questions.extraFile },
          }
        }
        console.log(payload)
        this.$store.commit('problem/SET_QUESTION_FEILD', payload)
      },
    },
    answers: {
      deep: true,
      handler() {
        this.answers[this.answerType].forEach((q, index) => {
          let payload
          if (this.answerType === 'text') {
            payload = {
              type: 'multiple', level: this.level, index, answer: q,
            }
          } else if (this.answerType === 'image') {
            payload = {
              type: 'multiple', level: this.level, index, answer: { image: q, imageUrl: this.answers.imageUrl[index], caption: this.answers.caption[index] },
            }
          } else if (this.answerType === 'audio') {
            payload = {
              type: 'multiple', level: this.level, index, answer: { audio: q, audioUrl: this.answers.audioUrl[index] },
            }
          }
          this.$store.commit('problem/SET_ANSWER_FEILD', payload)
        })
      },
    },
    activeQuestions(value) {
      this.setQuestions(value)
    },
    activeAnswers(value) {
      this.setAnswers(value)
    },
    getIsCorrect(value) {
      this.is_correct.answer = value[this.level]
    },
    is_correct: {
      deep: true,
      handler(value) {
        value.answer.forEach((c, index) => {
          const payload = {
            level: this.level, index, isCorrect: c,
          }
          this.$store.commit('problem/SET_IS_CORRECT_FEILD', payload)
        })
      },
    },
    showError(value) {
      if (value) {
        this.$refs.multipleForm.validate()
      }
    },
  },
  created() {
    this.setQuestions(this.activeQuestions)
    this.setAnswers(this.activeAnswers)
  },
  methods: {
    addQuestion() {
      this.$store.commit('problem/ADD_QUESTIONS')
    },
    askToDelete(j) {
      this.deletePayloadIndex = j
      this.$refs['modal-delete'].toggle()
    },
    deleteQuestion() {
      this.$store.commit('problem/DELETE_QUESTIONS', { index: this.deletePayloadIndex })
    },
    handleImage({ image, url, caption }, type, j) {
      if (type === 'questions') {
        this[type].image = image
        this[type].imageUrl = url
        this[type].caption = caption
      } else if (type === 'extra-file') {
        this.questions = {
          ...this.questions,
          extraFile: {
            caption,
            imageUrl: url,
            image,
          },
        }
        this.$forceUpdate()
      } else {
        this[type].image[j] = image
        const imgList = this[type].imageUrl
        imgList[j] = url
        this[type].imageUrl = [...imgList]
        const imgCaption = this[type].caption
        imgCaption[j] = caption
        this[type].caption = [...imgCaption]
      }
    },
    setAudio(j, type, { audio, url }) {
      if (type === 'questions') {
        this[type].audio = audio
        this[type].audioUrl = url
      } else {
        const audioObj = this[type]
        audioObj.audio[j] = audio
        audioObj.audioUrl[j] = url
        this[type] = { ...audioObj }
      }
    },
    setQuestions(value) {
      Object.keys(value).forEach(key => {
        const [q] = value[key][this.level]
        this.questions[key] = q
      })
    },
    setAnswers(value) {
      Object.keys(value).forEach(key => {
        value[key][this.level].forEach((a, index) => {
          this.answers[key][index] = a
        })
      })
    },
    setError(vid, msg) {
      this.$refs.multipleForm.setErrors({ [vid]: [msg] })
    },
  },
}
</script>
