<template>
  <b-modal :visible="show"
           title="Manage Lesson Animated Template"
           no-close-on-backdrop
           size="lg"
           @close="$emit('close')"
           @hide="$emit('close')"
  >
    <b-overlay :show="isProcessing">
      <b-form-group label="Template List">
        <v-select v-model="selectedTemplate"
                  :options="templateList"
                  label="text"
                  :reduce="i => i.value"
                  placeholder="Template Lists"
        />
      </b-form-group>
      <div>
        <b-form-group label="Template to apply at:">
          <b-form-checkbox-group
            id="checkbox-group-1"
            v-model="selectedArea"
            :options="applicableArea"
            name="flavour-1"
            class="demo-inline-spacing"
          />
        </b-form-group>
      </div>
      <div>
        <b-form-group label="For Specific Problems">
          <b-form-checkbox v-model="isSpecificProblemSelected"
                           switch
          />
        </b-form-group>
      </div>
      <div v-if="isSpecificProblemSelected"
           class="mt-2"
      >
        <h4>Problems</h4>
        <div class="row">
          <div v-for="item,index of problems"
               :key="index"
               class="col-md-3 d-flex mb-1"
          >
            <b-form-checkbox v-model="selectedProblem"
                             :value="item.id"
            >
              {{ item.name }}
            </b-form-checkbox>
          </div>
        </div>
      </div>
    </b-overlay>
    <template #modal-footer>
      <div>
        <b-button @click="$emit('close')">
          Cancel
        </b-button>
        <b-button class="ml-1"
                  variant="primary"
                  :disabled="isSaving"
                  @click="bulkUpdateTemplate"
        >
          <b-spinner v-if="isSaving"
                     small
          />  Submit
        </b-button>
      </div>
    </template>
  </b-modal>
</template>
<script>
import {
  BModal, BFormGroup, BFormCheckbox, BButton, BOverlay, BSpinner, BFormCheckboxGroup,
} from 'bootstrap-vue'
import useJwt from '@/auth/jwt/useJwt'
import vSelect from 'vue-select'

export default {
  components: {
    BModal,
    BFormCheckbox,
    BFormCheckboxGroup,
    vSelect,
    BFormGroup,
    BButton,
    BOverlay,
    BSpinner,
  },
  props: {
    lessonId: {
      type: Number,
      required: true,
    },
    show: {
      type: Boolean,
      default: false,
    },
    problems: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      isSaving: false,
      isProcessing: false,
      templateList: [],
      selectedTemplate: null,
      isSpecificProblemSelected: false,
      selectedProblem: [],
      selectedArea: ['body'],
      applicableArea: [
        {
          text: 'Statement',
          value: 'statement',
        },
        {
          text: 'Body',
          value: 'body',
        },
        {
          text: 'Feedback',
          value: 'feedback',
        },
      ],
    }
  },
  mounted() {
    this.getSavedAnimationTemplateCopy()
  },
  methods: {
    bulkUpdateTemplate() {
      this.isSaving = true
      useJwt.bulkUpdateAnimatedTemplate(this.lessonId, {
        template_id: this.selectedTemplate,
        specific_problem: this.isSpecificProblemSelected,
        selected_problem: this.selectedProblem,
        specific_area: this.selectedArea,
      }).then(response => {
        this.showSuccessMessage(response)
        this.$emit('fetchProblem')
        this.$emit('close')
      }).catch(error => {
        this.showErrorMessage(error)
      }).finally(() => {
        this.isSaving = false
      })
    },
    getSavedAnimationTemplateCopy() {
      this.isProcessing = true
      useJwt.getSavedAnimationTemplateCopy().then(response => {
        this.templateList = response.data.data.map(item => ({
          text: item.template_name,
          value: item.id,
        }))
      }).catch(error => {
        this.showErrorMessage(error)
      }).finally(() => {
        this.isProcessing = false
      })
    },
  },
}
</script>
